import { Box } from "@mui/material";
import { ReactElement, useState } from "react";
import StepHeader from "../StepHeader";
import { Meeting } from "../../../pages/BookMeeting";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoArrowForward } from "react-icons/io5";


export interface BookEnterpriseMeetingProps {
  onFinish: () => void;
  email: string;
  firstName: string;
  lastName: string;
  externalMeetingId: string;
  setOrgMeetingScheduled: () => void;
}

const BookEnterpriseMeeting = ({
  onFinish, email, firstName, lastName, externalMeetingId, setOrgMeetingScheduled
}: BookEnterpriseMeetingProps): ReactElement => {
  const [introBooked, setIntroBooked] = useState(false);
  
  const handleBookingComplete = (data?: {isIntroProcessComplete: boolean, shouldUpdateOrg: boolean}) => {
    if (data?.isIntroProcessComplete) {
      setIntroBooked(true);
    }
    if (data?.shouldUpdateOrg) {
      setOrgMeetingScheduled();
    }    
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <StepHeader
        title={"Congratulations!"}
        text="You qualify for 1 month-free of Cabinet and a white-glove onboarding call with our CEO. This call is a
         superb chance to ensure your integrations work and you have all of your questions answered."
      />
      <Meeting
        externalId={externalMeetingId}
        prefillEmail={email}
        prefillName={`${firstName} ${lastName}`}
        setBookingComplete={handleBookingComplete}
        noCabPage
        isEnterpriseBook
      />
      
      <CabButton
        size="large"
        endIcon={introBooked ? undefined : <CabIcon size="small" Icon={IoArrowForward} />}
        onClick={onFinish}
        sx={{ width: 190 }}
      >
        {introBooked ? 'Continue' : 'Skip'}
      </CabButton>
    </Box>
  );
};

export default BookEnterpriseMeeting;
