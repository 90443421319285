import { CabButton, CabTextInput } from "@CabComponents";
import { Alert, Box, Divider, FormControl, FormLabel, Link, styled, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ReactElement, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import StepHeader from "../StepHeader";
import colors from "../../../colors";
import { emailRegex, PAGE_URL, PRIVACY_POLICY, TERMS_OF_SERVICE } from "../../../constants";
import { signInWithRedirect as amplifySignInWithRedirect } from 'aws-amplify/auth';
import { GOOGLE_LOGO } from "../../../resourceUrls";
import { Organization } from "../../../store";
import { validateEmail } from '../../../utils/inputUtils';

export interface SignUpProps {
  onSubmit: (values: FormInput) => void;
  orgCode?: string;
  organization?: Organization;
  orgExists: boolean;
  autoSubmitEmail?: string;
  email?: string;
}

interface FormInput {
  email: string;
}

const SignUpStep = ({
  onSubmit, orgCode, organization, orgExists, autoSubmitEmail, email,
}: SignUpProps): ReactElement => {
  const { control, formState: { errors, isSubmitting }, handleSubmit: handleFormSubmit } = useForm<FormInput>({
    defaultValues: { email: autoSubmitEmail || email },
    reValidateMode: 'onBlur',
  });

  const navigate = useNavigate();  

  const handleOtherSignup = () => {
    navigate(PAGE_URL.SIGNUP);
    window.location.reload();
  };

  useEffect(() => {
    if (autoSubmitEmail) {
      handleFormSubmit(onSubmit)();
    }
  }, [autoSubmitEmail, handleFormSubmit, onSubmit]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%">
      <StepHeader
        title="Create an account"
        text={orgCode ? `Sign up with your ${organization?.name} account` : "Enter your work email to get started"}
      />

      {!orgCode ? (
        <form onSubmit={handleFormSubmit(onSubmit)} style={{ width: 'inherit' }}>
          <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
            <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%"
              maxWidth="350px" flex={1} alignItems="center"
            >
              <FormControl sx={{ flex: 1, width: '100%' }}>
                <FormLabel>Work email</FormLabel>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, pattern: emailRegex, validate: validateEmail }}
                  render={({ field: { ref, ...field } }) => (
                    <CabTextInput {...field} disabled={isSubmitting} />
                  )}
                />
                {errors.email && (
                  <Alert severity="error" sx={{ marginTop: 1 }}>
                    {errors.email.message
                  || (errors?.email?.type === 'required' ? 'Email is required'
                    : errors.email?.type === 'pattern' ? 'Please enter a valid email address'
                      : null)}
                  </Alert>
                )}
              </FormControl>

              <CabButton
                size="large"
                type="submit"
                sx={{ width: '100%' }}
                disabled={isSubmitting}
              >
                Let's go!
              </CabButton>

              <Divider sx={{ width: "100%" }}>
                <Typography variant="body2" color={colors.black600}>Or</Typography>
              </Divider>
              <CabButton
                size="large"
                buttonType="tertiary"
                color="accent"
                type="button"
                sx={{
                  width: '100%', color: colors.black800, borderColor: colors.black300, fontWeight: 700,
                  backgroundColor: colors.white900,
                }}
                disabled={isSubmitting}
                icon={<img src={GOOGLE_LOGO} height={24} width={24} alt="Google" style={{ marginRight: 6 }} />}
                //onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
                onClick={() => amplifySignInWithRedirect({ provider: "Google" })}
              >
                Sign up with Google
              </CabButton>
              <CabButton
                size="large"
                buttonType="tertiary"
                color="accent"
                type="button"
                sx={{
                  width: '100%', color: colors.black800, borderColor: colors.black300, fontWeight: 700,
                  backgroundColor: colors.white900,
                }}
                disabled={isSubmitting}
                onClick={() => navigate(PAGE_URL.SSO_SEARCH + `?redirect=${PAGE_URL.SIGNUP}`)}
              >
                Sign up with SSO
              </CabButton>
              <Box display="flex" gap={1}>
                <Typography variant="body2" color={colors.black600}>Already have an account?</Typography>
                <Link component={RouterLink} to={PAGE_URL.LOGIN} variant="body2" color={colors.black600}>Log in</Link>
              </Box>
              <Box display="flex" gap={1}>
                <Typography textAlign={'center'} variant="body2" color={colors.black600}>
                  By signing in to Cabinet, you agree to our&nbsp;
                  <Link href={PRIVACY_POLICY} color={colors.black600} target={'_blank'}>
                    Privacy Policy
                  </Link>
                  &nbsp;and&nbsp;
                  <Link href={TERMS_OF_SERVICE} color={colors.black600} target={'_blank'}>
                    Terms of Service
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>

        </form>
      ) : (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'} maxWidth={'350px'} 
          marginTop={2}>
          {orgExists ? (
            <>
              {organization?.logo && (
                <CompanyLogo src={organization.logo} alt={organization?.name ?? ""} />
              )}
              <CabButton
                size="large"
                buttonType="tertiary"
                color="accent"
                type="button"
                sx={{ width: '100%', color: colors.black800, borderColor: colors.black300, 
                  backgroundColor: colors.white900, fontWeight: 700 }}
                disabled={isSubmitting}
                onClick={() => amplifySignInWithRedirect({ 
                  provider: { 
                    custom: organization?.cognito_provider_name || '' 
                  }
                })}
              >
                Sign up for Cabinet
              </CabButton>
              <Box display="flex" marginTop={2}>
                <Typography variant="body2" color={colors.black600}>
                  Want to sign up with a different method?&nbsp;
                </Typography>
                <Link 
                  component={RouterLink} 
                  to={PAGE_URL.SIGNUP} 
                  onClick={() => handleOtherSignup()}
                  variant="body2" 
                  color={colors.black600}
                >
                  Sign up
                </Link>
              </Box>
              <Box display="flex" marginTop={2}>
                <Typography textAlign={'center'} variant="body2" color={colors.black600}>
                  By signing in to Cabinet, you agree to our&nbsp;
                  <Link href={PRIVACY_POLICY} color={colors.black600} target={'_blank'}>
                    Privacy Policy
                  </Link>
                  &nbsp;and&nbsp;
                  <Link href={TERMS_OF_SERVICE} color={colors.black600} target={'_blank'}>
                    Terms of Service
                  </Link>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="body1"> 
                This organization is not yet on Cabinet. Please contact your organization's administrator.
              </Typography>
              <Box display="flex" marginTop={2}>
                <Typography variant="body2" color={colors.black600}>
                  To go back to the sign up page&nbsp;
                </Typography>
                <Link 
                  component={RouterLink} 
                  to={PAGE_URL.SIGNUP} 
                  onClick={() => handleOtherSignup()}
                  variant="body2" 
                  color={colors.black600}
                >
                  click here
                </Link>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

const CompanyLogo = styled('img', { label: "CompanyLogo" })(
  {
    maxWidth: 250,
    maxHeight: 74,
    marginBottom: 24,
  }
);

export default SignUpStep;
