import { ReactElement, useCallback, useEffect, useState } from "react";
import queryString from 'query-string';
import SignUpStep from "./SignUp";
import { useLocation, useParams } from "react-router-dom";
import { Preferences } from "@capacitor/preferences";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState, ThunkDispatchType } from "../../../store";
import { getEmailDomain } from "../../../utils/authUtils";


interface UserInfo {
  email?: string;
}

export interface SignUpStepContainerProps {
  onUpdateUserInfo: ({ email }: { email: string }) => void;
  onFinish: (p: { userInfo: UserInfo }) => void;
  email?: string;
}

const SignUpStepContainer = ({
  onUpdateUserInfo, onFinish, email
}: SignUpStepContainerProps): ReactElement => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const location = useLocation();
  const [orgCode, setOrgCode] = useState<string>();
  const { orgCode: orgCodeParam } = useParams<{orgCode?: string}>();
  const organization = useSelector((state: RootState) => state.organization);
  const [orgExists, setOrgExist] = useState<boolean | undefined>();

  const { email: urlEmail } = queryString.parse(location.search) as { email?: string };

  useEffect(() => {
    if (orgCodeParam) {
      setOrgCode(orgCodeParam);
    }
  }, [orgCodeParam]);


  useEffect(() => {
    if (orgCode && orgExists === undefined) {
      dispatch(actions.organization.fetchPublicOrg(orgCode)).then(res => {
        if (res && res.status === 404) {
          setOrgExist(false);
        } else if (res?.status === 200) {
          setOrgExist(true);
        }
      });
    }
  }, [orgCode, dispatch, orgExists]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    Object.keys(parsed)
      .filter(key => ['planTier', 'planInterval', 'promoCode', 'referralCode'].includes(key))
      .forEach(key => {
        const value = parsed[key];
        if (value && typeof value === 'string') {
          Preferences.set({ key, value: value.toUpperCase() });
        }
      });
  }, [location.search]);

  const isSSO = useCallback(async (checkEmail: string) => {
    const domain = getEmailDomain(checkEmail);
    const res = await dispatch(actions.organization.fetchPublicOrgByDomain(domain));
    if (res.status === 200 && res.data.org_code) {
      setOrgExist(true);
      setOrgCode(res.data.org_code);
      return true;
    }
    return false;
  }, [dispatch]);

  const handleSubmit = useCallback(async (values: { email: string }) => {
    const ssoRequired = await isSSO(values.email);
    if (!ssoRequired) {
      onUpdateUserInfo(values);
      onFinish({ userInfo: { email: values.email }});
    }
  }, [isSSO, onFinish, onUpdateUserInfo]);

  return (
    <SignUpStep onSubmit={handleSubmit} orgCode={orgCode} orgExists={orgExists || false}
      organization={organization} autoSubmitEmail={orgCode ? undefined : urlEmail} email={email} />
  );
};

export default SignUpStepContainer;
