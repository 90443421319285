import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EVENT_TYPE } from "../../../constants";
import { actions, RootState, ThunkDispatchType } from "../../../store";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import IndividualCalendarStep from "./IndividualCalendarStep";

export interface IndividualCalendarStepContainerProps {
  onFinish: () => void;
  buttonText?: string;
}

const IndividualCalendarStepContainer = ({ 
  onFinish, buttonText 
}: IndividualCalendarStepContainerProps): ReactElement => {
  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const schedule = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch<ThunkDispatchType>();

  useEffect(() => {
    dispatch(actions.leaders.fetchLeaders());
    dispatch(actions.schedule.fetchRemoteCalendars());
    dispatch(actions.schedule.fetchCalendars());
  }, [dispatch]);

  const [selectedCalendarIds, setSelectedCalendarIds] = useState<number[]>(leaders.leaders 
    ? schedule.calendars.filter(assoc => assoc.leaders.includes(leaders.leaders[0].id))
      .map(assoc => assoc.id) 
    : []);

  const firstLeaderId = leaders.leaders?.[0]?.id || undefined;

  useEffect (() => {
    if (firstLeaderId != null) {
      setSelectedCalendarIds(schedule.calendars.filter(assoc => assoc.leaders
        .includes(firstLeaderId)).map(assoc => assoc.id));
    }
  }, [leaders.leaders.length, firstLeaderId, schedule.calendars]);

  const handleFinish = async (newSelectedCalendarIds: number[]) => {
    const userLeader = leaders.leaders.find(l => l.id === user?.profile.user_leader);
    if (!userLeader) return;

    //associate calendars with new leader
    let leaderCalendars = userLeader.leader_calendars;
    const associateCalendars = schedule.calendars.map(cal => {
      if (newSelectedCalendarIds.includes(cal.id)) {
        const newLeaderCalendar = {
          [cal.id]: {
            id: cal.id,
            prevent_conflict: true,
            allow_calendar_analytics: true,
            calendar: cal.id,
            leader: userLeader.id
          }
        };
        leaderCalendars = {...leaderCalendars, ...newLeaderCalendar};
      }
      return {
        leader_id: userLeader.id,
        provider: cal.provider,
        calId: cal.id,
        associate: newSelectedCalendarIds.includes(cal.id),
        prevent_conflict: true,
        allow_calendar_analytics: true
      };
    });

    if (associateCalendars.length === 0) {
      trackEvent(EVENT_TYPE.ONBOARD_SKIP_CALENDARS_EXEC);
    }
    await dispatch(actions.schedule.updateLeaderCalendars(associateCalendars));

    onFinish();
  };

  return <IndividualCalendarStep
    calendars={schedule.calendars}
    selectedCalendarIds={selectedCalendarIds}
    oauth_grant_details={user?.oauth_grant_details || {}}
    leaderExists={leaders.leaders.length > 0}
    onFinish={handleFinish}
    buttonText={buttonText}
  />;
};

export default IndividualCalendarStepContainer;
